
// const residencesSignatureHotSpotOne = { 
//     title: 'signature-01',
//     inMenu: false, 
//     type: 'nested',
//     src: './img/amenities/bkg--signature-residences-01.jpg',
//     iconUp: './img/amenities/icon--club.png',
//     iconDown: './img/amenities/icon--club-selected.png',
//     index: 1,
//     hotSpots: [
//         { nested: true, title: 'L11-garden-lounge', left: '1000px', top: '780px', align: 'bottom-right', src: './img/amenities/residences-building-10-tmb.png', href: './img/amenities/bkg--signature-residences-floorplate-01.jpg' },
//         { nested: true, title: 'L11-garden-lounge', left: '1095px', top: '705px', align: 'bottom-right', src: './img/amenities/residences-building-12-tmb.png', href: './img/amenities/bkg--signature-residences-floorplate-01.jpg' },
//         { nested: true, title: 'L11-garden-lounge', left: '1085px', top: '580px', align: 'bottom-right', src: './img/amenities/residences-building-09-tmb.png', href: './img/amenities/bkg--signature-residences-floorplate-01.jpg' },
//         { nested: true, title: 'L11-garden-lounge', left: '1095px', top: '780px', align: 'bottom-right', src: './img/amenities/residences-building-11-tmb.png', href: './img/amenities/bkg--signature-residences-floorplate-01.jpg' },
//         { nested: true, title: 'L11-garden-lounge', left: '1240px', top: '460px', align: 'bottom-right', src: './img/amenities/residences-building-08-tmb.png', href: './img/amenities/bkg--signature-residences-floorplate-01.jpg' },
//         { nested: true, title: 'L11-garden-lounge', left: '1290px', top: '510px', align: 'bottom-right', src: './img/amenities/residences-building-05-tmb.png', href: './img/amenities/bkg--signature-residences-floorplate-01.jpg' },
//         { nested: true, title: 'L11-garden-lounge', left: '1300px', top: '610px', align: 'bottom-right', src: './img/amenities/residences-building-06-tmb.png', href: './img/amenities/bkg--signature-residences-floorplate-01.jpg' },
//         { nested: true, title: 'L11-garden-lounge', left: '1300px', top: '760px', align: 'bottom-right', src: './img/amenities/residences-building-07-tmb.png', href: './img/amenities/bkg--signature-residences-floorplate-01.jpg' },
//     ]
// }


const residencesSignatureHotSpotTwo = { 
    title: 'signature-02',
    inMenu: false, 
    type: 'nested',
    src: './img/floor-plates/YPM004-floor-plates-02.jpg',
    iconUp: './img/amenities/icon--club.png',
    iconDown: './img/amenities/icon--club-selected.png',
    index: 2,
    hotSpots: [
        { viewsBtn: true, nested: true, rotation: 0, title: 'hotspot', left: '1100px', top: '790px', align: 'bottom-right', src: '', href: '/vr-app/Panorama2/index.htm' },
        { nested: true, rotation: 0, title: 'hotspot', left: '1420px', top: '775px', align: 'bottom-right', src: './img/amenities/residences-building-10-tmb.png', href: './img/gallery-building/gallery-building-14.jpg' },
        { nested: true, rotation: 0, title: 'hotspot', left: '1365px', top: '670px', align: 'bottom-right', src: './img/amenities/residences-building-12-tmb.png', href: './img/gallery-building/gallery-building-13.jpg' },
        { nested: true, rotation: 0, title: 'hotspot', left: '1395px', top: '565px', align: 'bottom-right', src: './img/amenities/residences-building-09-tmb.png', href: './img/gallery-building/gallery-building-12.jpg' },
        { nested: true, rotation: 0, title: 'hotspot', left: '1340px', top: '740px', align: 'bottom-right', src: './img/amenities/residences-building-11-tmb.png', href: './img/gallery-building/gallery-building-ensuite.jpg' },
        { nested: true, rotation: 0, title: 'hotspot', left: '840px', top: '510px', align: 'bottom-right', src: './img/amenities/residences-building-08-tmb.png', href: './img/gallery-building/gallery-building-butlers.jpg' },
        { nested: true, rotation: 0, title: 'hotspot', left: '900px', top: '595px', align: 'bottom-right', src: './img/amenities/residences-building-05-tmb.png', href: './img/gallery-building/gallery-building-kitchen.jpg' },
        { nested: true, rotation: 0, title: 'hotspot', left: '900px', top: '765px', align: 'bottom-right', src: './img/amenities/residences-building-07-tmb.png', href: './img/gallery-building/gallery-building-10.jpg' },
        { nested: true, rotation: 0, title: 'hotspot', left: '820px', top: '645px', align: 'bottom-right', src: './img/amenities/residences-building-06-tmb.png', href: './img/gallery-building/gallery-building-09.jpg' },
    ]
}


const residencesSignatureHotSpotThree = { 
    title: 'signature-03',
    inMenu: false, 
    type: 'nested',
    src: './img/floor-plates/YPM004-floor-plates-03.jpg',
    iconUp: './img/amenities/icon--club.png',
    iconDown: './img/amenities/icon--club-selected.png',
    index: 3,
    hotSpots: [
        { viewsBtn: true, nested: true,title: 'hotspot', left: '1250px', top: '680px', align: 'bottom-right', src: './img/amenities/residences-building-10-tmb.png', href: '/vr-app/Panorama3/index.htm' },
        { nested: true, rotation: 0, title: 'hotspot', left: '1400px', top: '780px', align: 'bottom-right', src: './img/amenities/residences-building-10-tmb.png', href: './img/gallery-building/gallery-building-14.jpg' },
        { nested: true, rotation: 0, title: 'hotspot', left: '1455px', top: '660px', align: 'bottom-right', src: './img/amenities/residences-building-12-tmb.png', href: './img/gallery-building/gallery-building-13.jpg' },
        { nested: true, rotation: 0, title: 'hotspot', left: '1425px', top: '560px', align: 'bottom-right', src: './img/amenities/residences-building-09-tmb.png', href: './img/gallery-building/gallery-building-12.jpg' },
        { nested: true, rotation: 0, title: 'hotspot', left: '1475px', top: '750px', align: 'bottom-right', src: './img/amenities/residences-building-11-tmb.png', href: './img/gallery-building/gallery-building-ensuite.jpg' },
        { nested: true, rotation: 0, title: 'hotspot', left: '925px', top: '495px', align: 'bottom-right', src: './img/amenities/residences-building-08-tmb.png', href: './img/gallery-building/gallery-building-butlers.jpg' },
        { nested: true, rotation: 0, title: 'hotspot', left: '885px', top: '590px', align: 'bottom-right', src: './img/amenities/residences-building-05-tmb.png', href: './img/gallery-building/gallery-building-kitchen.jpg' },
        { nested: true, rotation: 0, title: 'hotspot', left: '855px', top: '770px', align: 'bottom-right', src: './img/amenities/residences-building-07-tmb.png', href: './img/gallery-building/gallery-building-outdoor.jpg' },
        { nested: true, rotation: 0, title: 'hotspot', left: '950px', top: '670px', align: 'bottom-right', src: './img/amenities/residences-building-06-tmb.png', href: './img/gallery-building/gallery-building-09.jpg' },
    ]
}


const residencesSignatureHotSpotFour = { 
    title: 'signature-04',
    inMenu: false, 
    type: 'nested',
    src: './img/floor-plates/YPM004-floor-plates-04.jpg',
    iconUp: './img/amenities/icon--club.png',
    iconDown: './img/amenities/icon--club-selected.png',
    index: 4,
    hotSpots: [
        { viewsBtn: true, nested: true, rotation: 0, title: 'hotspot', left: '1100px', top: '790px', align: 'bottom-right', src: './img/amenities/residences-building-10-tmb.png', href: '/vr-app/Panorama4/index.htm' },
        { nested: true, rotation: 0, title: 'hotspot', left: '1420px', top: '775px', align: 'bottom-right', src: './img/amenities/residences-building-10-tmb.png', href: './img/gallery-building/gallery-building-14.jpg' },
        { nested: true, rotation: 0, title: 'hotspot', left: '1365px', top: '670px', align: 'bottom-right', src: './img/amenities/residences-building-12-tmb.png', href: './img/gallery-building/gallery-building-13.jpg' },
        { nested: true, rotation: 0, title: 'hotspot', left: '1395px', top: '565px', align: 'bottom-right', src: './img/amenities/residences-building-09-tmb.png', href: './img/gallery-building/gallery-building-12.jpg' },
        { nested: true, rotation: 0, title: 'hotspot', left: '1340px', top: '740px', align: 'bottom-right', src: './img/amenities/residences-building-11-tmb.png', href: './img/gallery-building/gallery-building-ensuite.jpg' },
        { nested: true, rotation: 0, title: 'hotspot', left: '840px', top: '510px', align: 'bottom-right', src: './img/amenities/residences-building-08-tmb.png', href: './img/gallery-building/gallery-building-butlers.jpg' },
        { nested: true, rotation: 0, title: 'hotspot', left: '900px', top: '595px', align: 'bottom-right', src: './img/amenities/residences-building-05-tmb.png', href: './img/gallery-building/gallery-building-kitchen.jpg' },
        { nested: true, rotation: 0, title: 'hotspot', left: '900px', top: '765px', align: 'bottom-right', src: './img/amenities/residences-building-07-tmb.png', href: './img/gallery-building/gallery-building-10.jpg' },
        { nested: true, rotation: 0, title: 'hotspot', left: '820px', top: '645px', align: 'bottom-right', src: './img/amenities/residences-building-06-tmb.png', href: './img/gallery-building/gallery-building-09.jpg' },
    ]
}


const residencesSignatureHotSpotFive = { 
    title: 'signature-05',
    inMenu: false, 
    type: 'nested',
    src: './img/floor-plates/YPM004-floor-plates-05.jpg',
    iconUp: './img/amenities/icon--club.png',
    iconDown: './img/amenities/icon--club-selected.png',
    index: 5,
    hotSpots: [
        { viewsBtn: true, nested: true,title: 'hotspot', left: '1250px', top: '680px', align: 'bottom-right', src: './img/amenities/residences-building-10-tmb.png', href: '/vr-app/Panorama5/index.htm' },
        { nested: true, rotation: 0, title: 'hotspot', left: '1400px', top: '780px', align: 'bottom-right', src: './img/amenities/residences-building-10-tmb.png', href: './img/gallery-building/gallery-building-14.jpg' },
        { nested: true, rotation: 0, title: 'hotspot', left: '1455px', top: '660px', align: 'bottom-right', src: './img/amenities/residences-building-12-tmb.png', href: './img/gallery-building/gallery-building-13.jpg' },
        { nested: true, rotation: 0, title: 'hotspot', left: '1425px', top: '560px', align: 'bottom-right', src: './img/amenities/residences-building-09-tmb.png', href: './img/gallery-building/gallery-building-12.jpg' },
        { nested: true, rotation: 0, title: 'hotspot', left: '1475px', top: '750px', align: 'bottom-right', src: './img/amenities/residences-building-11-tmb.png', href: './img/gallery-building/gallery-building-ensuite.jpg' },
        { nested: true, rotation: 0, title: 'hotspot', left: '925px', top: '495px', align: 'bottom-right', src: './img/amenities/residences-building-08-tmb.png', href: './img/gallery-building/gallery-building-butlers.jpg' },
        { nested: true, rotation: 0, title: 'hotspot', left: '885px', top: '590px', align: 'bottom-right', src: './img/amenities/residences-building-05-tmb.png', href: './img/gallery-building/gallery-building-kitchen.jpg' },
        { nested: true, rotation: 0, title: 'hotspot', left: '855px', top: '770px', align: 'bottom-right', src: './img/amenities/residences-building-07-tmb.png', href: './img/gallery-building/gallery-building-outdoor.jpg' },
        { nested: true, rotation: 0, title: 'hotspot', left: '950px', top: '670px', align: 'bottom-right', src: './img/amenities/residences-building-06-tmb.png', href: './img/gallery-building/gallery-building-09.jpg' },
    ]
}


const residencesSignatureHotSpotSix = { 
    title: 'signature-06',
    inMenu: false, 
    type: 'nested',
    src: './img/floor-plates/YPM004-floor-plates-06.jpg',
    iconUp: './img/amenities/icon--club.png',
    iconDown: './img/amenities/icon--club-selected.png',
    index: 6,
    hotSpots: [
        { viewsBtn: true, nested: true, rotation: 0, title: 'hotspot', left: '1100px', top: '790px', align: 'bottom-right', src: './img/amenities/residences-building-10-tmb.png', href: '/vr-app/Panorama6/index.htm' },
        { nested: true, rotation: 0, title: 'hotspot', left: '1420px', top: '775px', align: 'bottom-right', src: './img/amenities/residences-building-10-tmb.png', href: './img/gallery-building/gallery-building-14.jpg' },
        { nested: true, rotation: 0, title: 'hotspot', left: '1365px', top: '670px', align: 'bottom-right', src: './img/amenities/residences-building-12-tmb.png', href: './img/gallery-building/gallery-building-13.jpg' },
        { nested: true, rotation: 0, title: 'hotspot', left: '1395px', top: '565px', align: 'bottom-right', src: './img/amenities/residences-building-09-tmb.png', href: './img/gallery-building/gallery-building-12.jpg' },
        { nested: true, rotation: 0, title: 'hotspot', left: '1340px', top: '740px', align: 'bottom-right', src: './img/amenities/residences-building-11-tmb.png', href: './img/gallery-building/gallery-building-ensuite.jpg' },
        { nested: true, rotation: 0, title: 'hotspot', left: '840px', top: '510px', align: 'bottom-right', src: './img/amenities/residences-building-08-tmb.png', href: './img/gallery-building/gallery-building-butlers.jpg' },
        { nested: true, rotation: 0, title: 'hotspot', left: '900px', top: '595px', align: 'bottom-right', src: './img/amenities/residences-building-05-tmb.png', href: './img/gallery-building/gallery-building-kitchen.jpg' },
        { nested: true, rotation: 0, title: 'hotspot', left: '900px', top: '765px', align: 'bottom-right', src: './img/amenities/residences-building-07-tmb.png', href: './img/gallery-building/gallery-building-10.jpg' },
        { nested: true, rotation: 0, title: 'hotspot', left: '820px', top: '645px', align: 'bottom-right', src: './img/amenities/residences-building-06-tmb.png', href: './img/gallery-building/gallery-building-09.jpg' },
    ]
}


const residencesSignatureHotSpotSeven = { 
    title: 'signature-07',
    inMenu: false, 
    type: 'nested',
    src: './img/floor-plates/YPM004-floor-plates-07.jpg',
    iconUp: './img/amenities/icon--club.png',
    iconDown: './img/amenities/icon--club-selected.png',
    index: 7,
    hotSpots: [
        { viewsBtn: true, nested: true,title: 'hotspot', left: '1250px', top: '680px', align: 'bottom-right', src: './img/amenities/residences-building-10-tmb.png', href: '/vr-app/Panorama7/index.htm' },
        { nested: true, rotation: 0, title: 'hotspot', left: '1400px', top: '780px', align: 'bottom-right', src: './img/amenities/residences-building-10-tmb.png', href: './img/gallery-building/gallery-building-14.jpg' },
        { nested: true, rotation: 0, title: 'hotspot', left: '1455px', top: '660px', align: 'bottom-right', src: './img/amenities/residences-building-12-tmb.png', href: './img/gallery-building/gallery-building-13.jpg' },
        { nested: true, rotation: 0, title: 'hotspot', left: '1425px', top: '560px', align: 'bottom-right', src: './img/amenities/residences-building-09-tmb.png', href: './img/gallery-building/gallery-building-12.jpg' },
        { nested: true, rotation: 0, title: 'hotspot', left: '1475px', top: '750px', align: 'bottom-right', src: './img/amenities/residences-building-11-tmb.png', href: './img/gallery-building/gallery-building-ensuite.jpg' },
        { nested: true, rotation: 0, title: 'hotspot', left: '925px', top: '495px', align: 'bottom-right', src: './img/amenities/residences-building-08-tmb.png', href: './img/gallery-building/gallery-building-butlers.jpg' },
        { nested: true, rotation: 0, title: 'hotspot', left: '885px', top: '590px', align: 'bottom-right', src: './img/amenities/residences-building-05-tmb.png', href: './img/gallery-building/gallery-building-kitchen.jpg' },
        { nested: true, rotation: 0, title: 'hotspot', left: '855px', top: '770px', align: 'bottom-right', src: './img/amenities/residences-building-07-tmb.png', href: './img/gallery-building/gallery-building-outdoor.jpg' },
        { nested: true, rotation: 0, title: 'hotspot', left: '950px', top: '670px', align: 'bottom-right', src: './img/amenities/residences-building-06-tmb.png', href: './img/gallery-building/gallery-building-09.jpg' },
    ]
}


const residencesSignatureHotSpotEight = { 
    title: 'signature-08',
    inMenu: false, 
    type: 'nested',
    src: './img/floor-plates/YPM004-floor-plates-08.jpg',
    iconUp: './img/amenities/icon--club.png',
    iconDown: './img/amenities/icon--club-selected.png',
    index: 8,
    hotSpots: [
        { viewsBtn: true, nested: true, rotation: 0, title: 'hotspot', left: '1100px', top: '790px', align: 'bottom-right', src: './img/amenities/residences-building-10-tmb.png', href: '/vr-app/Panorama8/index.htm' },
        { nested: true, rotation: 0, title: 'hotspot', left: '1420px', top: '775px', align: 'bottom-right', src: './img/amenities/residences-building-10-tmb.png', href: './img/gallery-building/gallery-building-14.jpg' },
        { nested: true, rotation: 0, title: 'hotspot', left: '1365px', top: '670px', align: 'bottom-right', src: './img/amenities/residences-building-12-tmb.png', href: './img/gallery-building/gallery-building-13.jpg' },
        { nested: true, rotation: 0, title: 'hotspot', left: '1395px', top: '565px', align: 'bottom-right', src: './img/amenities/residences-building-09-tmb.png', href: './img/gallery-building/gallery-building-12.jpg' },
        { nested: true, rotation: 0, title: 'hotspot', left: '1340px', top: '740px', align: 'bottom-right', src: './img/amenities/residences-building-11-tmb.png', href: './img/gallery-building/gallery-building-ensuite.jpg' },
        { nested: true, rotation: 0, title: 'hotspot', left: '840px', top: '510px', align: 'bottom-right', src: './img/amenities/residences-building-08-tmb.png', href: './img/gallery-building/gallery-building-butlers.jpg' },
        { nested: true, rotation: 0, title: 'hotspot', left: '900px', top: '595px', align: 'bottom-right', src: './img/amenities/residences-building-05-tmb.png', href: './img/gallery-building/gallery-building-kitchen.jpg' },
        { nested: true, rotation: 0, title: 'hotspot', left: '900px', top: '765px', align: 'bottom-right', src: './img/amenities/residences-building-07-tmb.png', href: './img/gallery-building/gallery-building-10.jpg' },
        { nested: true, rotation: 0, title: 'hotspot', left: '820px', top: '645px', align: 'bottom-right', src: './img/amenities/residences-building-06-tmb.png', href: './img/gallery-building/gallery-building-09.jpg' },
    ]
}


const residencesSignatureHotSpotNine = { 
    title: 'signature-09',
    inMenu: false, 
    type: 'nested',
    src: './img/floor-plates/YPM004-floor-plates-09.jpg',
    iconUp: './img/amenities/icon--club.png',
    iconDown: './img/amenities/icon--club-selected.png',
    index: 9,
    hotSpots: [
        { viewsBtn: true, nested: true,title: 'hotspot', left: '1250px', top: '680px', align: 'bottom-right', src: './img/amenities/residences-building-10-tmb.png', href: '/vr-app/Panorama9/index.htm' },
        { nested: true, rotation: 0, title: 'hotspot', left: '1400px', top: '780px', align: 'bottom-right', src: './img/amenities/residences-building-10-tmb.png', href: './img/gallery-building/gallery-building-14.jpg' },
        { nested: true, rotation: 0, title: 'hotspot', left: '1455px', top: '660px', align: 'bottom-right', src: './img/amenities/residences-building-12-tmb.png', href: './img/gallery-building/gallery-building-13.jpg' },
        { nested: true, rotation: 0, title: 'hotspot', left: '1425px', top: '560px', align: 'bottom-right', src: './img/amenities/residences-building-09-tmb.png', href: './img/gallery-building/gallery-building-12.jpg' },
        { nested: true, rotation: 0, title: 'hotspot', left: '1475px', top: '750px', align: 'bottom-right', src: './img/amenities/residences-building-11-tmb.png', href: './img/gallery-building/gallery-building-ensuite.jpg' },
        { nested: true, rotation: 0, title: 'hotspot', left: '925px', top: '495px', align: 'bottom-right', src: './img/amenities/residences-building-08-tmb.png', href: './img/gallery-building/gallery-building-butlers.jpg' },
        { nested: true, rotation: 0, title: 'hotspot', left: '885px', top: '590px', align: 'bottom-right', src: './img/amenities/residences-building-05-tmb.png', href: './img/gallery-building/gallery-building-kitchen.jpg' },
        { nested: true, rotation: 0, title: 'hotspot', left: '855px', top: '770px', align: 'bottom-right', src: './img/amenities/residences-building-07-tmb.png', href: './img/gallery-building/gallery-building-outdoor.jpg' },
        { nested: true, rotation: 0, title: 'hotspot', left: '950px', top: '670px', align: 'bottom-right', src: './img/amenities/residences-building-06-tmb.png', href: './img/gallery-building/gallery-building-09.jpg' },
    ]
}


const residencesSignatureHotSpotTen = { 
    title: 'signature-10',
    inMenu: false, 
    type: 'nested',
    src: './img/floor-plates/YPM004-floor-plates-10.jpg',
    iconUp: './img/amenities/icon--club.png',
    iconDown: './img/amenities/icon--club-selected.png',
    index: 10,
    hotSpots: [
        { viewsBtn: true, nested: true, rotation: 0, title: 'hotspot', left: '1100px', top: '790px', align: 'bottom-right', src: './img/amenities/residences-building-10-tmb.png', href: '/vr-app/Panorama10/index.htm' },
        { nested: true, rotation: 0, title: 'hotspot', left: '1420px', top: '775px', align: 'bottom-right', src: './img/amenities/residences-building-10-tmb.png', href: './img/gallery-building/gallery-building-14.jpg' },
        { nested: true, rotation: 0, title: 'hotspot', left: '1365px', top: '670px', align: 'bottom-right', src: './img/amenities/residences-building-12-tmb.png', href: './img/gallery-building/gallery-building-13.jpg' },
        { nested: true, rotation: 0, title: 'hotspot', left: '1395px', top: '565px', align: 'bottom-right', src: './img/amenities/residences-building-09-tmb.png', href: './img/gallery-building/gallery-building-12.jpg' },
        { nested: true, rotation: 0, title: 'hotspot', left: '1340px', top: '740px', align: 'bottom-right', src: './img/amenities/residences-building-11-tmb.png', href: './img/gallery-building/gallery-building-ensuite.jpg' },
        { nested: true, rotation: 0, title: 'hotspot', left: '840px', top: '510px', align: 'bottom-right', src: './img/amenities/residences-building-08-tmb.png', href: './img/gallery-building/gallery-building-butlers.jpg' },
        { nested: true, rotation: 0, title: 'hotspot', left: '900px', top: '595px', align: 'bottom-right', src: './img/amenities/residences-building-05-tmb.png', href: './img/gallery-building/gallery-building-kitchen.jpg' },
        { nested: true, rotation: 0, title: 'hotspot', left: '900px', top: '765px', align: 'bottom-right', src: './img/amenities/residences-building-07-tmb.png', href: './img/gallery-building/gallery-building-10.jpg' },
        { nested: true, rotation: 0, title: 'hotspot', left: '820px', top: '645px', align: 'bottom-right', src: './img/amenities/residences-building-06-tmb.png', href: './img/gallery-building/gallery-building-09.jpg' },
    ]
}


const residencesSignatureHotSpotEleven = { 
    title: 'signature-11',
    inMenu: false, 
    type: 'nested',
    src: './img/floor-plates/YPM004-floor-plates-11.jpg',
    iconUp: './img/amenities/icon--club.png',
    iconDown: './img/amenities/icon--club-selected.png',
    index:11,
    hotSpots: [
        { viewsBtn: true, nested: true,title: 'hotspot', left: '1250px', top: '680px', align: 'bottom-right', src: './img/amenities/residences-building-10-tmb.png', href: '/vr-app/Panorama11/index.htm' },
        { nested: true, rotation: 0, title: 'hotspot', left: '1400px', top: '780px', align: 'bottom-right', src: './img/amenities/residences-building-10-tmb.png', href: './img/gallery-building/gallery-building-14.jpg' },
        { nested: true, rotation: 0, title: 'hotspot', left: '1455px', top: '660px', align: 'bottom-right', src: './img/amenities/residences-building-12-tmb.png', href: './img/gallery-building/gallery-building-13.jpg' },
        { nested: true, rotation: 0, title: 'hotspot', left: '1425px', top: '560px', align: 'bottom-right', src: './img/amenities/residences-building-09-tmb.png', href: './img/gallery-building/gallery-building-12.jpg' },
        { nested: true, rotation: 0, title: 'hotspot', left: '1475px', top: '750px', align: 'bottom-right', src: './img/amenities/residences-building-11-tmb.png', href: './img/gallery-building/gallery-building-ensuite.jpg' },
        { nested: true, rotation: 0, title: 'hotspot', left: '925px', top: '495px', align: 'bottom-right', src: './img/amenities/residences-building-08-tmb.png', href: './img/gallery-building/gallery-building-butlers.jpg' },
        { nested: true, rotation: 0, title: 'hotspot', left: '885px', top: '590px', align: 'bottom-right', src: './img/amenities/residences-building-05-tmb.png', href: './img/gallery-building/gallery-building-kitchen.jpg' },
        { nested: true, rotation: 0, title: 'hotspot', left: '855px', top: '770px', align: 'bottom-right', src: './img/amenities/residences-building-07-tmb.png', href: './img/gallery-building/gallery-building-outdoor.jpg' },
        { nested: true, rotation: 0, title: 'hotspot', left: '950px', top: '670px', align: 'bottom-right', src: './img/amenities/residences-building-06-tmb.png', href: './img/gallery-building/gallery-building-09.jpg' },
    ]
}


const residencesSignatureHotSpotTwelve = { 
    title: 'signature-12',
    inMenu: false, 
    type: 'nested',
    src: './img/floor-plates/YPM004-floor-plates-12.jpg',
    iconUp: './img/amenities/icon--club.png',
    iconDown: './img/amenities/icon--club-selected.png',
    index: 12,
    hotSpots: [
        { viewsBtn: true, nested: true, rotation: 0, title: 'hotspot', left: '1100px', top: '790px', align: 'bottom-right', src: './img/amenities/residences-building-10-tmb.png', href: '/vr-app/Panorama12/index.htm' },
        { nested: true, rotation: 0, title: 'hotspot', left: '1420px', top: '775px', align: 'bottom-right', src: './img/amenities/residences-building-10-tmb.png', href: './img/gallery-building/gallery-building-14.jpg' },
        { nested: true, rotation: 0, title: 'hotspot', left: '1365px', top: '670px', align: 'bottom-right', src: './img/amenities/residences-building-12-tmb.png', href: './img/gallery-building/gallery-building-13.jpg' },
        { nested: true, rotation: 0, title: 'hotspot', left: '1395px', top: '565px', align: 'bottom-right', src: './img/amenities/residences-building-09-tmb.png', href: './img/gallery-building/gallery-building-12.jpg' },
        { nested: true, rotation: 0, title: 'hotspot', left: '1340px', top: '740px', align: 'bottom-right', src: './img/amenities/residences-building-11-tmb.png', href: './img/gallery-building/gallery-building-ensuite.jpg' },
        { nested: true, rotation: 0, title: 'hotspot', left: '840px', top: '510px', align: 'bottom-right', src: './img/amenities/residences-building-08-tmb.png', href: './img/gallery-building/gallery-building-butlers.jpg' },
        { nested: true, rotation: 0, title: 'hotspot', left: '900px', top: '595px', align: 'bottom-right', src: './img/amenities/residences-building-05-tmb.png', href: './img/gallery-building/gallery-building-kitchen.jpg' },
        { nested: true, rotation: 0, title: 'hotspot', left: '900px', top: '765px', align: 'bottom-right', src: './img/amenities/residences-building-07-tmb.png', href: './img/gallery-building/gallery-building-10.jpg' },
        { nested: true, rotation: 0, title: 'hotspot', left: '820px', top: '645px', align: 'bottom-right', src: './img/amenities/residences-building-06-tmb.png', href: './img/gallery-building/gallery-building-09.jpg' },
    ]
}


const residencesSignatureHotSpotThirteen = { 
    title: 'signature-13',
    inMenu: false, 
    type: 'nested',
    src: './img/floor-plates/YPM004-floor-plates-13.jpg',
    iconUp: './img/amenities/icon--club.png',
    iconDown: './img/amenities/icon--club-selected.png',
    index: 13,
    hotSpots: [
        { viewsBtn: true, nested: true,title: 'hotspot', left: '1250px', top: '680px', align: 'bottom-right', src: './img/amenities/residences-building-10-tmb.png', href: '/vr-app/Panorama13/index.htm' },
        { nested: true, rotation: 0, title: 'hotspot', left: '1400px', top: '780px', align: 'bottom-right', src: './img/amenities/residences-building-10-tmb.png', href: './img/gallery-building/gallery-building-14.jpg' },
        { nested: true, rotation: 0, title: 'hotspot', left: '1455px', top: '660px', align: 'bottom-right', src: './img/amenities/residences-building-12-tmb.png', href: './img/gallery-building/gallery-building-13.jpg' },
        { nested: true, rotation: 0, title: 'hotspot', left: '1425px', top: '560px', align: 'bottom-right', src: './img/amenities/residences-building-09-tmb.png', href: './img/gallery-building/gallery-building-12.jpg' },
        { nested: true, rotation: 0, title: 'hotspot', left: '1475px', top: '750px', align: 'bottom-right', src: './img/amenities/residences-building-11-tmb.png', href: './img/gallery-building/gallery-building-ensuite.jpg' },
        { nested: true, rotation: 0, title: 'hotspot', left: '925px', top: '495px', align: 'bottom-right', src: './img/amenities/residences-building-08-tmb.png', href: './img/gallery-building/gallery-building-butlers.jpg' },
        { nested: true, rotation: 0, title: 'hotspot', left: '885px', top: '590px', align: 'bottom-right', src: './img/amenities/residences-building-05-tmb.png', href: './img/gallery-building/gallery-building-kitchen.jpg' },
        { nested: true, rotation: 0, title: 'hotspot', left: '855px', top: '770px', align: 'bottom-right', src: './img/amenities/residences-building-07-tmb.png', href: './img/gallery-building/gallery-building-outdoor.jpg' },
        { nested: true, rotation: 0, title: 'hotspot', left: '950px', top: '670px', align: 'bottom-right', src: './img/amenities/residences-building-06-tmb.png', href: './img/gallery-building/gallery-building-09.jpg' },
    ]
}


const residencesSignatureHotSpotFourteen= { 
    title: 'signature-14',
    inMenu: false, 
    type: 'nested',
    src: './img/floor-plates/YPM004-floor-plates-14.jpg',
    iconUp: './img/amenities/icon--club.png',
    iconDown: './img/amenities/icon--club-selected.png',
    index: 14,
    hotSpots: [
        { viewsBtn: true, nested: true, rotation: 0, title: 'hotspot', left: '1100px', top: '790px', align: 'bottom-right', src: './img/amenities/residences-building-10-tmb.png', href: '/vr-app/Panorama14/index.htm' },
        { nested: true, rotation: 0, title: 'hotspot', left: '1420px', top: '775px', align: 'bottom-right', src: './img/amenities/residences-building-10-tmb.png', href: './img/gallery-building/gallery-building-14.jpg' },
        { nested: true, rotation: 0, title: 'hotspot', left: '1365px', top: '670px', align: 'bottom-right', src: './img/amenities/residences-building-12-tmb.png', href: './img/gallery-building/gallery-building-13.jpg' },
        { nested: true, rotation: 0, title: 'hotspot', left: '1395px', top: '565px', align: 'bottom-right', src: './img/amenities/residences-building-09-tmb.png', href: './img/gallery-building/gallery-building-12.jpg' },
        { nested: true, rotation: 0, title: 'hotspot', left: '1340px', top: '740px', align: 'bottom-right', src: './img/amenities/residences-building-11-tmb.png', href: './img/gallery-building/gallery-building-ensuite.jpg' },
        { nested: true, rotation: 0, title: 'hotspot', left: '840px', top: '510px', align: 'bottom-right', src: './img/amenities/residences-building-08-tmb.png', href: './img/gallery-building/gallery-building-butlers.jpg' },
        { nested: true, rotation: 0, title: 'hotspot', left: '900px', top: '595px', align: 'bottom-right', src: './img/amenities/residences-building-05-tmb.png', href: './img/gallery-building/gallery-building-kitchen.jpg' },
        { nested: true, rotation: 0, title: 'hotspot', left: '900px', top: '765px', align: 'bottom-right', src: './img/amenities/residences-building-07-tmb.png', href: './img/gallery-building/gallery-building-10.jpg' },
        { nested: true, rotation: 0, title: 'hotspot', left: '820px', top: '645px', align: 'bottom-right', src: './img/amenities/residences-building-06-tmb.png', href: './img/gallery-building/gallery-building-09.jpg' },
    ]
}


const residencesSignatureHotSpotFifteen = { 
    title: 'signature-15',
    inMenu: false, 
    type: 'nested',
    src: './img/floor-plates/YPM004-floor-plates-15.jpg',
    iconUp: './img/amenities/icon--club.png',
    iconDown: './img/amenities/icon--club-selected.png',
    index: 15,
    hotSpots: [
        { viewsBtn: true, nested: true,title: 'hotspot', left: '1250px', top: '680px', align: 'bottom-right', src: './img/amenities/residences-building-10-tmb.png', href: '/vr-app/Panorama15/index.htm' },
        { nested: true, rotation: 0, title: 'hotspot', left: '1400px', top: '780px', align: 'bottom-right', src: './img/amenities/residences-building-10-tmb.png', href: './img/gallery-building/gallery-building-14.jpg' },
        { nested: true, rotation: 0, title: 'hotspot', left: '1455px', top: '660px', align: 'bottom-right', src: './img/amenities/residences-building-12-tmb.png', href: './img/gallery-building/gallery-building-13.jpg' },
        { nested: true, rotation: 0, title: 'hotspot', left: '1425px', top: '560px', align: 'bottom-right', src: './img/amenities/residences-building-09-tmb.png', href: './img/gallery-building/gallery-building-12.jpg' },
        { nested: true, rotation: 0, title: 'hotspot', left: '1475px', top: '750px', align: 'bottom-right', src: './img/amenities/residences-building-11-tmb.png', href: './img/gallery-building/gallery-building-ensuite.jpg' },
        { nested: true, rotation: 0, title: 'hotspot', left: '925px', top: '495px', align: 'bottom-right', src: './img/amenities/residences-building-08-tmb.png', href: './img/gallery-building/gallery-building-butlers.jpg' },
        { nested: true, rotation: 0, title: 'hotspot', left: '885px', top: '590px', align: 'bottom-right', src: './img/amenities/residences-building-05-tmb.png', href: './img/gallery-building/gallery-building-kitchen.jpg' },
        { nested: true, rotation: 0, title: 'hotspot', left: '855px', top: '770px', align: 'bottom-right', src: './img/amenities/residences-building-07-tmb.png', href: './img/gallery-building/gallery-building-outdoor.jpg' },
        { nested: true, rotation: 0, title: 'hotspot', left: '950px', top: '670px', align: 'bottom-right', src: './img/amenities/residences-building-06-tmb.png', href: './img/gallery-building/gallery-building-09.jpg' },
    ]
}

// const residencesOakmontB1 = { 
//     title: 'OakmontB1',
//     inMenu: false, 
//     type: 'nested',
//     src: './img/amenities/bkg--floorplates-oakmontcollection-01.jpg',
//     iconUp: './img/amenities/icon--club.png',
//     iconDown: './img/amenities/icon--club-selected.png',
//     index: 21,
//     hotSpots: [
//         { nested: true, rotation: 0, title: 'hotspot', left: '1400px', top: '780px', align: 'bottom-right', src: './img/galleries-oakmont/gallery-oakmont-04-tmb.jpg', href: './img/galleries-oakmont/gallery-oakmont-04.jpg' },

//     ]
// }

const residencesOakmontB2 = { 
    title: 'signature-15',
    inMenu: false, 
    type: 'nested',
    src: './img/amenities/bkg--floorplates-oakmontcollection-02.jpg',
    iconUp: './img/amenities/icon--club.png',
    iconDown: './img/amenities/icon--club-selected.png',
    index: 15,
    hotSpots: [
        // { viewsBtn: true, nested: true,title: 'hotspot', left: '1250px', top: '680px', align: 'bottom-right', src: './img/amenities/residences-building-10-tmb.png', href: '/vr-app/Panorama3/index.htm' },
        { nested: true, rotation: 0, title: 'hotspot', left: '680px', top: '515px', align: 'bottom-right', src: './img/residences-oakmont/residences-oakmont-04-tmb.png', href: './img/residences-oakmont/residences-oakmont-04.jpg' },
    ]
}

const residencesOakmontB1 = { 
    title: 'signature-15',
    inMenu: false, 
    type: 'nested',
    src: './img/amenities/bkg--floorplates-oakmontcollection-01.jpg',
    iconUp: './img/amenities/icon--club.png',
    iconDown: './img/amenities/icon--club-selected.png',
    index: 15,
    hotSpots: [
        // { viewsBtn: true, nested: true,title: 'hotspot', left: '1250px', top: '680px', align: 'bottom-right', src: './img/amenities/residences-building-10-tmb.png', href: '/vr-app/Panorama3/index.htm' },
        { nested: true, rotation: 0, title: 'hotspot', left: '725px', top: '535px', align: 'bottom-right', src: './img/residences-oakmont/residences-oakmont-04-tmb.png',  href: './img/residences-oakmont/residences-oakmont-04.jpg' },
    ]
}

export const data = [{
    id: 16,
    levels: [
        { 
            title: 'A CLUB', 
            inMenu: true, 
            marginTop: '90px',
            src: './img/amenities/bkg--residences-1.jpg',
            iconUp: './img/amenities/btn-aclub.png',
            iconDown: './img/amenities/btn-aclub.png',
            marginTop: '-170px',

            index: 17,
            navigateTo: 'club-2'
            
        },



        { 
            title: 'club-2', 
            inMenu: false, 
            gallery: true,
            srcOne: './img/amenities/bkg--aclub-1.jpg',
            srcTwo: './img/amenities/bkg--aclub-2.jpg',
            iconUp: './img/amenities/btn-aclub.png',
            iconDown: './img/amenities/btn-aclub.png',
            index: 17,
            hotSpotsOne: [
                { nested: true, title: 'signature-01', left: '870px', top: '510px', align: 'bottom-right', src: './img/amenities/gallery-tmb-amenities-09.png', href:  './img/gallery-amenities/gallery-amenities-11.jpg' },
                { nested: true, title: 'signature-02', left: '820px', top: '470px', align: 'bottom-right', src: './img/amenities/gallery-tmb-amenities-10.png', href:  './img/gallery-building/gallery-building-02.jpg' },
                { nested: true, title: 'signature-03', left: '870px', top: '560px', align: 'bottom-right', src: './img/amenities/gallery-tmb-amenities-08.png', href:  './img/gallery-amenities/gallery-amenities-10.jpg' },
                { nested: true, title: 'signature-04', left: '990px', top: '490px', align: 'bottom-right', src: './img/amenities/gallery-tmb-amenities-13.png', href:  './img/gallery-amenities/gallery-amenities-05.jpg' },
                { nested: true, title: 'signature-05', left: '1140px', top: '510px', align: 'bottom-right', src: './img/amenities/gallery-tmb-amenities-02.png', href:  './img/gallery-amenities/gallery-amenities-02.jpg' },
                { nested: true, title: 'signature-06', left: '1140px', top: '460px', align: 'bottom-right', src: './img/amenities/gallery-tmb-amenities-04.png', href:  './img/gallery-amenities/gallery-amenities-04.jpg' },
                { nested: true, title: 'signature-07', left: '1140px', top: '410px', align: 'bottom-right', src: './img/amenities/gallery-tmb-amenities-12.png', href:  './img/gallery-amenities/gallery-amenities-09.jpg' },
                { nested: true, title: 'signature-08', left: '1260px', top: '520px', align: 'bottom-right', src: './img/amenities/gallery-tmb-amenities-03.png', href:  './img/gallery-amenities/gallery-amenities-03.jpg' },
                { nested: true, title: 'signature-09', left: '1320px', top: '520px', align: 'bottom-right', src: './img/amenities/gallery-tmb-amenities-01.png', href:  './img/gallery-amenities/gallery-amenities-01.jpg' },
                { nested: true, title: 'signature-10', left: '1200px', top: '640px', align: 'bottom-right', src: './img/amenities/gallery-tmb-amenities-11.png', href:  './img/gallery-amenities/gallery-amenities-12.jpg' },
            
            ],
            hotSpotsTwo: [
                { nested: true,title: 'signature-11', left: '675px', top: '215px', align: 'bottom-right', src: './img/amenities/gallery-tmb-amenities-06.png', href:  './img/gallery-amenities/gallery-amenities-07.jpg' },
                { nested: true,title: 'signature-12', left: '855px', top: '215px', align: 'bottom-right', src: './img/amenities/gallery-tmb-amenities-07.png', href:  './img/gallery-amenities/gallery-amenities-08.jpg' },
                { nested: true,title: 'signature-13', left: '680px', top: '335px', align: 'bottom-right', src: './img/amenities/gallery-tmb-amenities-05.png', href:  './img/gallery-amenities/gallery-amenities-06.jpg' },
                { nested: true,title: 'signature-14', left: '1100px', top: '255px', align: 'bottom-right', src: './img/amenities/gallery-tmb-amenities-12.png', href:  './img/gallery-amenities/gallery-amenities-09.jpg' },
                { nested: true,title: 'signature-15', left: '880px', top: '435px', align: 'bottom-right', src: './img/amenities/gallery-tmb-amenities-04.png', href:  './img/gallery-amenities/gallery-amenities-04.jpg' },
                { nested: true,title: 'signature-16', left: '880px', top: '635px', align: 'bottom-right', src: './img/amenities/gallery-tmb-amenities-02.png', href:  './img/gallery-amenities/gallery-amenities-02.jpg' },
                { nested: true,title: 'signature-17', left: '1330px', top: '525px', align: 'bottom-right', src: './img/amenities/gallery-tmb-amenities-03.png', href:  './img/gallery-amenities/gallery-amenities-03.jpg' },
                { nested: true,title: 'signature-18', left: '1380px', top: '700px', align: 'bottom-right', src: './img/amenities/gallery-tmb-amenities-01.png', href:  './img/gallery-amenities/gallery-amenities-01.jpg' },
                { nested: true,title: 'signature-19', left: '520px', top: '710px', align: 'bottom-right', src: './img/amenities/gallery-tmb-amenities-13.png', href:  './img/gallery-amenities/gallery-amenities-05.jpg' },
            ]
        },
        
        
        { 
            title: 'SIGNATURE COLLECTION', 
            inMenu: true,
            marginTop: '-30px',

            src: './img/amenities/bkg--residences-2.jpg',
            iconUp: './img/amenities/btn-signature.png',
            iconDown: './img/amenities/btn-signature.png',
            index: 19,
            hotSpots: [
                // { title: 'signature-01', left: '1305px', top: '250px', rotation: '0', align: 'bottom-right2', src: './img/amenities/residences-tmb-01.png', href:  residencesSignatureHotSpotOne },
                { title: 'signature-02',left: '1225px', top: '250px', rotation: '0', align: 'bottom-right2', src: './img/amenities/residences-tmb-02.png', href: residencesSignatureHotSpotTwo },
                { title: 'signature-03', left: '1170px', top: '250px', rotation: '0', align: 'bottom-right2', src: './img/amenities/residences-tmb-03.png', href: residencesSignatureHotSpotThree },
                { title: 'signature-04', left: '1070px', top: '250px', rotation: '0', align: 'bottom-right2', src: './img/amenities/residences-tmb-04.png', href: residencesSignatureHotSpotFour },
                { title: 'signature-05', left: '1015px', top: '250px', rotation: '0', align: 'bottom-right2', src: './img/amenities/residences-tmb-05.png', href: residencesSignatureHotSpotFive },
                { title: 'signature-06', left: '920px', top: '250px', rotation: '0', align: 'bottom-right2', src: './img/amenities/residences-tmb-06.png', href: residencesSignatureHotSpotSix },
                { title: 'signature-07', left: '865px', top: '250px', rotation: '0', align: 'bottom-right2', src: './img/amenities/residences-tmb-07.png', href: residencesSignatureHotSpotSeven },
                { title: 'signature-08', left: '770px', top: '250px', rotation: '0', align: 'bottom-right2', src: './img/amenities/residences-tmb-08.png', href: residencesSignatureHotSpotEight },
                { title: 'signature-09', left: '710px', top: '250px', rotation: '0', align: 'bottom-right2', src: './img/amenities/residences-tmb-09.png', href: residencesSignatureHotSpotNine },
                { title: 'signature-10', left: '600px', top: '285px', rotation: '-16', align: 'bottom-right2', src: './img/amenities/residences-tmb-10.png', href: residencesSignatureHotSpotTen },
                { title: 'signature-11', left: '545px', top: '300px', rotation: '-16', align: 'bottom-right2', src: './img/amenities/residences-tmb-11.png', href: residencesSignatureHotSpotEleven },
                { title: 'signature-12', left: '460px', top: '360px', rotation: '-16', align: 'bottom-right2', src: './img/amenities/residences-tmb-12.png', href: residencesSignatureHotSpotTwelve },
                { title: 'signature-13', left: '405px', top: '380px', rotation: '-16', align: 'bottom-right2', src: './img/amenities/residences-tmb-13.png', href: residencesSignatureHotSpotThirteen },
                { title: 'signature-14', left: '330px', top: '440px', rotation: '-16', align: 'bottom-right2', src: './img/amenities/residences-tmb-14.png', href: residencesSignatureHotSpotFourteen },
                { title: 'signature-15', left: '275px', top: '450px', rotation: '-16', align: 'bottom-right2', src: './img/amenities/residences-tmb-15.png', href: residencesSignatureHotSpotFifteen },
            ]
        },
        { 
            title: 'OAKMONT COLLECTION', 
            inMenu: true,
            marginTop: '210px',
            iconUp: './img/amenities/btn-signature.png',
            iconDown: './img/amenities/btn-signature.png',
            src: './img/amenities/bkg--residences-oakmontcollection.jpg',

            index: 20,
            hotSpots: [
                { nested: true, color: 'white', title: 'oakmont-2627',left: '740px', top: '430px', rotation: '90', width: '35px', height: '85px' ,align: 'top-right', src: './img/residences-oakmont/residences-oakmont-01-tmb.png', href:  './img/residences-oakmont/residences-oakmont-01.jpg'},
                { nested: true, color: 'white', title: 'oakmont-3839',left: '795px', top: '680px', rotation: '90', width: '35px', height: '85px' ,align: 'top-right', src: './img/residences-oakmont/residences-oakmont-02-tmb.png', href:  './img/residences-oakmont/residences-oakmont-02.jpg'},
                { nested: true, color: 'white', title: 'oakmont-5657',left: '1120px', top: '920px', rotation: '90', width: '35px', height: '85px' ,align: 'top-right', src: './img/residences-oakmont/residences-oakmont-03-tmb.png', href:  './img/residences-oakmont/residences-oakmont-03.jpg'},
                { nested: true, color: 'white', title: 'oakmont-20-25',left: '465px', top: '235px', rotation: '90', width: '35px', height: '85px' ,align: 'bottom-right', src: './img/residences-oakmont/residences-oakmont-05-tmb.png', href:  './img/residences-oakmont/residences-oakmont-05.jpg'},

                { variableSize: true, title: 'oakmont-01',left: '480px', top: '620px', rotation: '90', width: '35px', height: '85px' ,align: 'top-right', src: './img/amenities/residences-tmb-21.png', href:  './img/amenities/bkg--floorplates-oakmontcollection-06.jpg'},
                { variableSize: true, title: 'oakmont-02',left: '480px', top: '580px', rotation: '90', width: '35px', height: '85px' ,align: 'top-right', src: './img/amenities/residences-tmb-20.png', href:  './img/amenities/bkg--floorplates-oakmontcollection-05.jpg'},
                { variableSize: true, title: 'oakmont-03',left: '460px', top: '530px', rotation: '90', width: '35px', height: '85px' ,align: 'top-right', src: './img/amenities/residences-tmb-21.png', href:  './img/amenities/bkg--floorplates-oakmontcollection-06.jpg'},
                { variableSize: true, title: 'oakmont-04',left: '460px', top: '490px', rotation: '90', width: '35px', height: '85px' ,align: 'top-right', src: './img/amenities/residences-tmb-20.png', href:  './img/amenities/bkg--floorplates-oakmontcollection-05.jpg'},
                { variableSize: true, title: 'oakmont-05',left: '513px', top: '410px', rotation: '152', width: '35px', height: '85px' ,align: 'top-right', src: './img/amenities/residences-tmb-21.png', href:  './img/amenities/bkg--floorplates-oakmontcollection-06.jpg'},
                { variableSize: true, title: 'oakmont-06',left: '550px', top: '390px', rotation: '152', width: '35px', height: '85px' ,align: 'top-right', src: './img/amenities/residences-tmb-20.png', href:  './img/amenities/bkg--floorplates-oakmontcollection-05.jpg'},
                { variableSize: true, title: 'oakmont-07',left: '595px', top: '365px', rotation: '152', width: '35px', height: '85px' ,align: 'top-right', src: './img/amenities/residences-tmb-21.png', href:  './img/amenities/bkg--floorplates-oakmontcollection-06.jpg'},
                { variableSize: true, title: 'oakmont-08',left: '630px', top: '345px', rotation: '152', width: '35px', height: '85px' ,align: 'top-right', src: './img/amenities/residences-tmb-20.png', href:  './img/amenities/bkg--floorplates-oakmontcollection-05.jpg'},
                { variableSize: true, title: 'oakmont-09',left: '675px', top: '325px', rotation: '152', width: '35px', height: '85px' ,align: 'top-right', src: './img/amenities/residences-tmb-21.png', href:  './img/amenities/bkg--floorplates-oakmontcollection-06.jpg'},
                { variableSize: true, title: 'oakmont-10',left: '710px', top: '305px', rotation: '152', width: '35px', height: '85px' ,align: 'top-right', src: './img/amenities/residences-tmb-20.png', href:  './img/amenities/bkg--floorplates-oakmontcollection-05.jpg'},
                { variableSize: true, title: 'oakmont-11',left: '755px', top: '280px', rotation: '152', width: '35px', height: '85px' ,align: 'top-right', src: './img/amenities/residences-tmb-21.png', href:  './img/amenities/bkg--floorplates-oakmontcollection-06.jpg'},
                { variableSize: true, title: 'oakmont-12',left: '790px', top: '260px', rotation: '152', width: '35px', height: '85px' ,align: 'bottom-right', src: './img/amenities/residences-tmb-20.png', href:  './img/amenities/bkg--floorplates-oakmontcollection-05.jpg'},
                { variableSize: true, title: 'oakmont-13',left: '835px', top: '235px', rotation: '152', width: '35px', height: '85px' ,align: 'bottom-right', src: './img/amenities/residences-tmb-21.png', href:  './img/amenities/bkg--floorplates-oakmontcollection-06.jpg'},
                { variableSize: true, title: 'oakmont-14',left: '870px', top: '220px', rotation: '152', width: '35px', height: '85px' ,align: 'bottom-right', src: './img/amenities/residences-tmb-20.png', href:  './img/amenities/bkg--floorplates-oakmontcollection-05.jpg'},
                { variableSize: true, title: 'oakmont-15',left: '915px', top: '195px', rotation: '152', width: '35px', height: '85px' ,align: 'bottom-right', src: './img/amenities/residences-tmb-21.png', href:  './img/amenities/bkg--floorplates-oakmontcollection-06.jpg'},
                { variableSize: true, title: 'oakmont-16',left: '950px', top: '180px', rotation: '152', width: '35px', height: '85px' ,align: 'bottom-right', src: './img/amenities/residences-tmb-20.png', href:  './img/amenities/bkg--floorplates-oakmontcollection-05.jpg'},
                { variableSize: true, title: 'oakmont-17',left: '995px', top: '155px', rotation: '152', width: '35px', height: '85px' ,align: 'bottom-right', src: './img/amenities/residences-tmb-21.png', href:  './img/amenities/bkg--floorplates-oakmontcollection-06.jpg'},
                { variableSize: true, title: 'oakmont-18',left: '1030px', top: '140px', rotation: '152', width: '35px', height: '85px' ,align: 'bottom-right', src: './img/amenities/residences-tmb-20.png', href:  './img/amenities/bkg--floorplates-oakmontcollection-05.jpg'},
                { variableSize: true, title: 'oakmont-19',left: '1075px', top: '110px', rotation: '152', width: '35px', height: '85px' ,align: 'bottom-right', src: './img/amenities/residences-tmb-21.png', href:  './img/amenities/bkg--floorplates-oakmontcollection-06.jpg'},
                { variableSize: true, title: 'oakmont-20',left: '1110px', top: '90px', rotation: '152', width: '35px', height: '85px' ,align: 'bottom-right', src: './img/amenities/residences-tmb-20.png', href:  './img/amenities/bkg--floorplates-oakmontcollection-05.jpg'},

                { variableSize: true, title: 'oakmont-21',left: '650px', top: '565px', rotation: '0', width: '45px', height: '80px' ,align: 'bottom-right', src: './img/amenities/residences-tmb-18.png', href:  './img/amenities/bkg--floorplates-oakmontcollection-03.jpg'},
                { variableSize: true, title: 'oakmont-22',left: '700px', top: '565px', rotation: '0', width: '45px', height: '80px' ,align: 'bottom-right', src: './img/amenities/residences-tmb-19.png', href:  './img/amenities/bkg--floorplates-oakmontcollection-04.jpg'},
                { variableSize: true, title: 'oakmont-23',left: '770px', top: '565px', rotation: '0', width: '45px', height: '80px' ,align: 'bottom-right', src: './img/amenities/residences-tmb-18.png', href:  './img/amenities/bkg--floorplates-oakmontcollection-03.jpg'},
                { variableSize: true, title: 'oakmont-24',left: '820px', top: '565px', rotation: '0', width: '45px', height: '80px' ,align: 'bottom-right', src: './img/amenities/residences-tmb-19.png', href:  './img/amenities/bkg--floorplates-oakmontcollection-04.jpg'},
                { variableSize: true, title: 'oakmont-25',left: '870px', top: '565px', rotation: '0', width: '45px', height: '80px' ,align: 'bottom-right', src: './img/amenities/residences-tmb-18.png', href:  './img/amenities/bkg--floorplates-oakmontcollection-03.jpg'},
                { variableSize: true, title: 'oakmont-26',left: '920px', top: '565px', rotation: '0', width: '45px', height: '80px' ,align: 'bottom-right', src: './img/amenities/residences-tmb-19.png', href:  './img/amenities/bkg--floorplates-oakmontcollection-04.jpg'},
                { variableSize: true, title: 'oakmont-27',left: '1030px', top: '565px', rotation: '0', width: '45px', height: '80px' ,align: 'bottom-right', src: './img/amenities/residences-tmb-18.png', href:  './img/amenities/bkg--floorplates-oakmontcollection-03.jpg'},
                { variableSize: true, title: 'oakmont-28',left: '1080px', top: '565px', rotation: '0', width: '45px', height: '80px' ,align: 'bottom-right', src: './img/amenities/residences-tmb-19.png', href:  './img/amenities/bkg--floorplates-oakmontcollection-04.jpg'},
                { variableSize: true, title: 'oakmont-29',left: '1130px', top: '565px', rotation: '0', width: '45px', height: '80px' ,align: 'bottom-right', src: './img/amenities/residences-tmb-18.png', href:  './img/amenities/bkg--floorplates-oakmontcollection-03.jpg'},
                { variableSize: true, title: 'oakmont-30',left: '1180px', top: '565px', rotation: '0', width: '45px', height: '80px' ,align: 'bottom-right', src: './img/amenities/residences-tmb-19.png', href:  './img/amenities/bkg--floorplates-oakmontcollection-04.jpg'},
                { variableSize: true, title: 'oakmont-31',left: '1245px', top: '565px', rotation: '0', width: '45px', height: '80px' ,align: 'bottom-right', src: './img/amenities/residences-tmb-18.png', href:  './img/amenities/bkg--floorplates-oakmontcollection-03.jpg'},
                { variableSize: true, title: 'oakmont-32',left: '1295px', top: '565px', rotation: '0', width: '45px', height: '80px' ,align: 'bottom-right', src: './img/amenities/residences-tmb-19.png', href:  './img/amenities/bkg--floorplates-oakmontcollection-04.jpg'},

                { variableSize: true, title: 'oakmont-33',left: '535px', top: '765px', rotation: '0', width: '55px', height: '120px' ,align: 'top-right', src: './img/amenities/residences-tmb-17.png', href:  residencesOakmontB2},
                { variableSize: true, title: 'oakmont-34',left: '585px', top: '765px', rotation: '0', width: '55px', height: '120px' ,align: 'top-right', src: './img/amenities/residences-tmb-16.png', href:  residencesOakmontB1},
                { variableSize: true, title: 'oakmont-35',left: '640px', top: '765px', rotation: '0', width: '55px', height: '120px' ,align: 'top-right', src: './img/amenities/residences-tmb-17.png', href:  residencesOakmontB2},
                { variableSize: true, title: 'oakmont-36',left: '695px', top: '765px', rotation: '0', width: '55px', height: '120px' ,align: 'top-right', src: './img/amenities/residences-tmb-16.png', href:  residencesOakmontB1},
                { variableSize: true, title: 'oakmont-37',left: '760px', top: '750px', rotation: '0', width: '55px', height: '120px' ,align: 'top-right', src: './img/amenities/residences-tmb-17.png', href:  residencesOakmontB2},
                { variableSize: true, title: 'oakmont-38',left: '810px', top: '750px', rotation: '0', width: '55px', height: '120px' ,align: 'top-right', src: './img/amenities/residences-tmb-16.png', href:  residencesOakmontB1},
                { variableSize: true, title: 'oakmont-39',left: '865px', top: '750px', rotation: '0', width: '55px', height: '120px' ,align: 'top-right', src: './img/amenities/residences-tmb-17.png', href:  residencesOakmontB2},
                { variableSize: true, title: 'oakmont-40',left: '920px', top: '750px', rotation: '0', width: '55px', height: '120px' ,align: 'top-right', src: './img/amenities/residences-tmb-16.png', href:  residencesOakmontB1},
                { variableSize: true, title: 'oakmont-41',left: '1035px', top: '750px', rotation: '0', width: '55px', height: '120px' ,align: 'top-right', src: './img/amenities/residences-tmb-17.png', href: residencesOakmontB2},
                { variableSize: true, title: 'oakmont-42',left: '1090px', top: '750px', rotation: '0', width: '55px', height: '120px' ,align: 'top-right', src: './img/amenities/residences-tmb-16.png', href: residencesOakmontB1},
                { variableSize: true, title: 'oakmont-43',left: '1135px', top: '750px', rotation: '0', width: '55px', height: '120px' ,align: 'top-right', src: './img/amenities/residences-tmb-17.png', href: residencesOakmontB2},
                { variableSize: true, title: 'oakmont-44',left: '1190px', top: '750px', rotation: '0', width: '55px', height: '120px' ,align: 'top-right', src: './img/amenities/residences-tmb-16.png', href: residencesOakmontB1},
                { variableSize: true, title: 'oakmont-45',left: '1240px', top: '750px', rotation: '0', width: '55px', height: '120px' ,align: 'top-right', src: './img/amenities/residences-tmb-17.png', href: residencesOakmontB2},
                { variableSize: true, title: 'oakmont-46',left: '1290px', top: '750px', rotation: '0', width: '55px', height: '120px' ,align: 'top-right', src: './img/amenities/residences-tmb-16.png', href: residencesOakmontB1},

            ]
        },
    ]
}];

